import React, {useState} from 'react';
import YoutubePlayer from '../../components/youtube_player';
import CenteredText from '../../components/Core/CenteredText';
import InputComboInline from '../../components/Core/InputComboInline';
import DownloadController from '../../components/DownloadController';
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import axios from 'axios';

const YoutubeTools = ({accountInfo}) => {
    const [addr, setAddr] = useState('')
    const [videoId, setVideoId] = useState(null)
    const [downloadButtonText, setDownloadButtonText] = useState('Download')
    const [downloadFileType, setDownloadFileType] = useState('mp3')
    const [resolution, setResolution] = useState(720)
    const [audioQuality, setAudioQuality] = useState(192)
    const [startSec, setStartSec] = useState(-1)
    const [endSec, setEndSec] = useState(-1)
    const [enableOption, setEnableOption] = useState(false)
    const downloadFileTypeList  = ["mp3", "mp4"]
    const resolutionList = [480,720,1024]
    const audioQualityList = [64,128,192,320]
    const { enqueueSnackbar } = useSnackbar();
  
    const handleSetAddress = (value) => {
      setAddr(value)
      setVideoId(extractVideoId(value))
    }
  
    const handledownloadFileType = (value) => {
      setDownloadFileType(value)
    }
  
    const handleOptionFlag = (value) => {
      setEnableOption(!value)
      setResolution(720);
      setAudioQuality(192);
      setStartSec(-1)
      setEndSec(-1);
    }
    
    const setVideoInfo = (value) => {
      console.log(value);
    }
  
    const handleDownloadClick = async () => {
      if(addr==="" || extractVideoId(addr) === null){
        console.log("not addr!")
        return
      }
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_SERVER_ADDR}/api/youtube/v1/download_by_url`, { 
          keyId: accountInfo.uuid,
          addr: addr,
          type: downloadFileType,
          resolution: (downloadFileType=== 'mp3')?audioQuality:resolution,
          start_time: startSec,
          end_time: endSec
        });
        const url = response.data;
        if (url && url.addr) {
          if(url.addr.includes(`${process.env.REACT_APP_S3_BUCKET}`)){
            enqueueSnackbar('Complete download!', 'success');
            window.open(url.addr, '_blank');
            setDownloadButtonText("Download");
          }else{
            setDownloadButtonText("in progress");
            setTimeout(() => {
              console.log("in progress");
              handleDownloadClick()
            }, 3000);
          }
        }
      } catch (error) {
        console.error('Error occurred while downloading:', error);
        setDownloadButtonText("Download");
      }
    }
  
    const extractVideoId = (url) => {
      const isShortsUrl = url.includes("/shorts/");
      if (isShortsUrl) {
        const match = url.match(/shorts\/([^?&]+)/);
        if (match) {
          return match[1];
        }
      }
      const match = url.match(/[?&]v=([^&]+)/);
      if (match) {
        return match[1];
      }
      return null;
    }
  
    return (
      <div>
        <header className="App-header">
          <CenteredText 
            text="Download Youtube videos"
            fontSize="1.5em"
            fontColor="black"
            color="0xff00ee"
            fontFamily="BlackHanSans-Regular"
            textAlign="center"
          />
          <InputComboInline 
            inputWidth={'20rem'}
            onInputChange={handleSetAddress} 
            selectedTextValue={downloadFileType}
            comboTextList={downloadFileTypeList} 
            onSelected={handledownloadFileType}
          />
          <FormGroup><FormControlLabel control={<Checkbox color="primary"  checked={enableOption} onChange={() => handleOptionFlag(enableOption)}/>} label="Options" /></FormGroup>
          {enableOption && (
              (downloadFileType === "mp4")?
              (
                <DownloadController 
                  downloadType={downloadFileType}
                  selectedValue={resolution}
                  startSec={startSec}
                  onSetStartSec={setStartSec}
                  endSec={endSec}
                  onSetEndSec={setEndSec}
                  comboTextList={resolutionList}
                  onSelected={setResolution}
                />
              ):(
                <DownloadController 
                  downloadType={downloadFileType}
                  selectedValue={audioQuality}
                  startSec={startSec}
                  onSetStartSec={setStartSec}
                  endSec={endSec}
                  onSetEndSec={setEndSec}
                  comboTextList={audioQualityList}
                  onSelected={setAudioQuality}
                />
                
              )
            )
          }
          {(videoId) && (
            <div style={{ display: 'flex' }}>
              <YoutubePlayer  videoId={videoId} videoUrl={addr} cbVideoInfo={setVideoInfo}/>
              <div >
                <Button 
                  variant="contained" 
                  color="primary"
                  disabled={downloadButtonText === "in progress"}
                  style={{ marginLeft: '2rem', position: 'relative', top: '30%'}} 
                  sx={{ m: 2, width: '8rem', height: '3rem' }}
                  onClick={handleDownloadClick}
                > 
                  {downloadButtonText} 
                </Button>
              </div>
            </div>
          )}
        </header>

        {/** 기능 설명 */}
        <div style={{ width: '80%', margin: '0 auto' }}>

        </div>
      </div>
    );
};

export default YoutubeTools;