import React, { useState } from 'react';
import CenteredText from '../../components/Core/CenteredText';
import axios from 'axios';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ColoredComboBox from '../../components/Core/ColoredComboBox';
import ImageFixedWidth from '../../components/Core/ImageFixedWidth';
import ColoredMessageBox from '../../components/Core/ColoredMessageBox';
import ImageEditor from '../../components/ImageEditor';
import { waitFor } from '../../utils/timer';
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import SaveIcon from '@mui/icons-material/Save';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Fade from '@mui/material/Fade';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Png2Ico = ({accountInfo}) => {
    const [selectedFile, setSelectedFile]  =useState('')
    const [imageOriginal, setImageOriginal] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [imageCIS, setImageCIS] = useState();
    const [modifiedSize, setModifiedSize] = useState(0)
    const [curDataUrl, setCurDataUrl] = useState(null)
    const [iconSize, setIconSize] = useState(32);
    const [downloadAddr, setDownloadAddr] = useState(null)
    const [bEditing, setEditing] = useState(false);
    const icoSizeList = [16,32,64,128,256]
    const [errorMsgInfo, setErrorMsg] = useState(null)
    const [waiting, setWaiting] = useState(false)
    const [expanded, setExpanded] = useState(false);

    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };

    const handleEditingFlag = (flag) => {
        setEditing(!flag);
    }

    const handleCloseDlg = () => {
        setErrorMsg(null);
    }
    const handleIconSize = (size) => {
        setIconSize(size);
        setEditing(false);
    }

    const loadImage = (file) => {
        setSelectedFile(file)
        setModifiedSize(0)
        setEditing(false)
        if(file){
            const reader = new FileReader();
            reader.onload = async (e) => {
                setImageSrc(e.target.result);
                setImageOriginal(e.target.result);
                setImageCIS(null)
                
                const canvas = document.createElement('canvas');
                canvas.width = iconSize;
                canvas.height = iconSize;

                const image = new Image();
                image.src = e.target.result;
                image.onload = () => {
                    setImageCIS(image);
                    const width = image.naturalWidth;
                    const height = image.naturalHeight;
                    if(width !== height){
                        //  crop 해야 함.
                        setEditing(true);
                    }
                };
                setDownloadAddr(null)
            }
            reader.readAsDataURL(file);
        }
    }

    const handleFileChange = async (event) => {
        event.preventDefault();
        loadImage(event.target.files[0]);
    };

    const Upload = async () => {
        try {
            const resDataUrl = await fetch(imageSrc);
            const blob = await resDataUrl.blob();

            const formData = new FormData();
            formData.append('image_file', blob, selectedFile.name);
            formData.append('keyId', accountInfo.uuid);
            formData.append('iconSize', iconSize);

            const response = await axios.post(`${process.env.REACT_APP_API_SERVER_ADDR}/api/converter/v1/toico`, formData, { 
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            await waitFor(1000);
            try {
                // Decode base64 string
                let binaryData = window.atob(response.data.addr, 'base64');
                let arrayBuffer = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    arrayBuffer[i] = binaryData.charCodeAt(i);
                }
            
                // Create a blob and download the file
                const blob = new Blob([arrayBuffer.buffer], { type: 'image/x-icon' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'icon.ico';
                link.click();
                window.URL.revokeObjectURL(url);
            } catch (e) {
                console.log(e);
                setErrorMsg({
                    msg: 'Failed to download the ICO file',
                    dlgType: 'OK',
                    color: 'error',
                });
            }

        } catch (e) {
            setDownloadAddr(null)
            if(e.response){
                setErrorMsg({'msg':e.response.statusText, 'dlgType': 'OK', 'color': 'error'})
            }else{    
                console.log(e);
                setErrorMsg({'msg':'Failed to download. Retry 2', 'dlgType': 'OK', 'color': 'error'})
            }
        }
        setWaiting(false)
    }

    const handleUpload = async () => {
        if (selectedFile) {
            setWaiting(true)
            await Upload();
        }
    }

    const handleDownload = () => 
    {
        if(downloadAddr === null){
            return;
        }

        console.log(downloadAddr)
        
        var a = document.createElement('A');
        a.href = downloadAddr;
        a.download = downloadAddr.substr(downloadAddr.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const resetByOriginal = () => {
        loadImage(selectedFile)
    }

    const DownloadImage = (dataUrl) => {
        setImageSrc(dataUrl);   //  변경된 이미지를 설정. original은 그대로 둠.
        setModifiedSize(iconSize);
        setImageCIS(null)

        const image = new Image();
        image.src = dataUrl
        image.onload = () => {
            setImageCIS(image);
            const width = image.naturalWidth;
            const height = image.naturalHeight;
            if(width !== height){
                //  crop 해야 함.
                setEditing(true);
            }
        };
        setDownloadAddr(null)        
    }

    const DownloadDirectly = (dataUrl) => {
        const image = new Image();
        image.src = dataUrl
        image.onload = () => {
            const tempCanvas = document.createElement('canvas');        
            const tempContext = tempCanvas.getContext('2d');
            tempCanvas.width = iconSize;
            tempCanvas.height = iconSize;
            tempContext.drawImage(image, 0, 0, iconSize, iconSize);
            const dataUrl = tempCanvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = selectedFile.name;
            link.click();
        };
    }

    const txtReadMe = (
    <Typography style={{textAlign:'left'}}>
        1. Select an image to convert to ICO format. <br/>
        2. Choose the size of the icon. <br/>
        3. Click the "Convert to ICO" button. <br/>
        4. Click the "DOWNLOAD" button to download the converted ICO file. <br/><br/>
        If you want to edit the image, click the "Edit" checkbox. <br/>
        1. Click the <SaveIcon color="primary" />(Apply) button to save the edited image. <br/>
        2. Click the <SaveAltIcon color="primary" />(Download now) button to save the edited image directly. <br/>
        3. Click the "Reset" button to reset the edited image to the original image. <br/>
        4. Click the <UndoIcon color="primary"/>(Undo) button to undo the last edit. <br/>
        5. Click the <RedoIcon color="primary"/>(Reod) button to redo the last edit. <br/>
    </Typography>);

    const txtPrivacy = (
    <Typography style={{textAlign:'left'}}>
        Our tool does not modify your original images. When you upload an image, the original file is unmodified and untouched on your computer or mobile phone or tablet. Additionally, we do not store your images on the server, all images uploaded and generated will be removed automatically after one hour, so you don’t need to worry, your privacy are protected.
    </Typography>);

    const txtIco = (
    <Typography  style={{textAlign:'left'}}>
    The .ICO file format was defined and created by the Microsoft corporation, .ico files are widely used in the Windows operating systems include Windows 7, Windows 10, Windows 11, etc. On the desktop of Windows, each application shortcut has an icon in .ico format, the icon can be scale in or out. One .ico image can contain several separate static images, each image's width usually is same as its height.<br />
    The most common favicon size is 16x16 or 32x32 pixels. There are places, however, where the favicon size needs to be a little larger. Here's a good breakdown of favicon dimensions and their uses in pixels: Browser favicons - 16x16.
    </Typography>);

    return (
        <div>
            <header className="App-header">
                <CenteredText 
                    text="Convert image to icon"
                    fontSize="1.5em"
                    fontColor="black"
                    color="0xff00ee"
                    fontFamily="BlackHanSans-Regular"
                    textAlign="center"
                />

                <div style={{display:"flex"}}>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ m: 2}}
                    >
                    Select image file
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange} accept="image/*"
                    />
                    </Button>                    
                    <ColoredComboBox 
                        textList={icoSizeList}
                        selectedText={iconSize} 
                        onTextChange={handleIconSize} 
                        label={"Icon Size"}
                        width={"6rem"}
                    />
                    {(modifiedSize>0) && <Button variant="outlined" size="small" onClick={() => resetByOriginal()}>Reset</Button>}
                </div>
                <div>
                    {(selectedFile && !waiting && !downloadAddr) && (<Button
                        variant="contained"
                        sm={{ m: 2}}
                        onClick={handleUpload}
                    >
                        Convert to ICO
                    </Button>)}
                    <div style={{position: "relative"}}>
                        {waiting && <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 9999}}></div>}
                        {waiting && <CircularProgress style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}} />}
                    </div>
                {(!waiting && downloadAddr) && (<Button
                        variant="outlined"
                        sm={{ m: 2}}
                        onClick={handleDownload}
                    >
                        DOWNLOAD
                    </Button>)}                    
                </div>
                
                <br/>
                {imageSrc && (
                    <>
                    <div>
                        <ImageFixedWidth imageSrc={imageSrc} imageCIS={imageCIS} resolution={iconSize} bDrawGrid={false} />
                        <FormGroup><FormControlLabel control={<Checkbox color="primary"  checked={bEditing} onChange={() => handleEditingFlag(bEditing)}/>} label="Edit" /></FormGroup>
                    </div>
                    <div  style={{ display: 'flex', alignItems: 'center' }}>
                        {bEditing && <ImageEditor imageCIS={imageCIS} resolution={iconSize} cbSave={DownloadImage} cbSaveInLocal={DownloadDirectly}/>}
                    </div>
                    </>
                )}
                {errorMsgInfo && <ColoredMessageBox message={errorMsgInfo.msg}  buttonType={errorMsgInfo.dlgType} onButtonClick={handleCloseDlg} type={errorMsgInfo.color}/>}
            </header>
            <div className="App-desc" >
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <Grid container spacing={1}>
                        <Grid xs m={2}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-readme"
                                id="panel-header-readme"
                                >
                                <Typography><b>Read me!</b></Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {txtReadMe}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid xs={6} m={2}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-privacy"
                                id="panel-header-privacy"
                                >
                                <Typography><b>How is the uploaded file used?</b></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {txtPrivacy}
                                </AccordionDetails>
                            </Accordion>       
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <Grid container spacing={1}>
                        <Grid xs m={2}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-ico"
                                id="panel-header-ico"
                                >
                                <Typography><b>What is the ICO file format?</b></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {txtIco}
                                </AccordionDetails>
                            </Accordion>        
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'contents', md: 'none' } }}>
                    <Accordion >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-readme"
                        id="panel-header-readme"
                        >
                        <Typography><b>Read me!</b></Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            {txtReadMe}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-privacy"
                        id="panel-header-privacy"
                        >
                        <Typography><b>How is the uploaded file used?</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {txtPrivacy}
                        </AccordionDetails>
                    </Accordion>       
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-ico"
                        id="panel-header-ico"
                        >
                        <Typography><b>What is the ICO file format?</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {txtIco}
                        </AccordionDetails>
                    </Accordion>        
                </Box>

            </div>
        </div>
    );
};

export default Png2Ico;
