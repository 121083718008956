import React from 'react';
import TextField from '@mui/material/TextField';

const ColoredComboBox = ({ label, helperText, width, textList, selectedText, onTextChange }) => {
    const handleTextChange = (e) => {
        onTextChange(e.target.value);
    };
    return (
        
        <TextField
          id="outlined-select-currency"
          select
          label={label}
          defaultValue={selectedText}
          onChange={handleTextChange}
          variant="standard"
          color="warning"
          sx={{ m: 2, width: {width} }}
          SelectProps={{
            native: true,
          }}
          helperText={helperText}
          focused
        >
            {textList.length && textList.map((value) => (
                <option key={value} value={value}>
                {value}
                </option>
            ))}
        </TextField>        
    );
};

export default ColoredComboBox;
