import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

function Home({ accountInfo }) {
    const [expanded, setExpanded] = React.useState(true);

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    return (
        <div style={{ width: '80%', margin: '0 auto' }}>
            <p>Welcome! Try our free online tool for everyone.</p>

            <p><br/></p>

            <Accordion expanded={expanded}>
                <AccordionSummary>
                    <Typography style={{ textAlign: 'left' }}>Diversity and Convenience</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ textAlign: 'left' }}>Our platform offers a wide array of tools to cater to your diverse needs. For instance, you can easily convert images into different formats, transform YouTube videos for various uses, and even edit images to perfection. These are just a few examples of the many functionalities our tools provide.</Typography>
                </AccordionDetails>
            </Accordion>

            <p><br/></p>

            <Accordion expanded={expanded}>
                <AccordionSummary>
                    <Typography style={{ textAlign: 'left' }}>Privacy Commitment</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ textAlign: 'left' }}>We understand the importance of protecting your privacy. That's why we guarantee that our tools do not use your personal information. We do not use your personal information, and all used resources are automatically deleted within 1 day.</Typography>
                </AccordionDetails>
            </Accordion>

            <p><br/></p>

            <p> ... </p>
        </div>
    );
}














// <Accordion expanded={expanded} onChange={handleAccordionChange}>
export default Home;

