import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import TextField from '@mui/material/TextField';
import ColoredComboBox from './Core/ColoredComboBox';

// type is a value of mui like success, error, secondary, and etc.
const NewProjectDialog = ({cbProjectSetting}) => {
    const [open, setOpen] = useState(true);
    const pixelSizeList = [16,32,64] // ,128,256]
    const [title, setTitle] = useState("");
    const [pixelSize, setPixelSize] = useState(32);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ style: { backgroundColor: "Primary" } }}>
            <DialogTitle>New Project</DialogTitle>
            <DialogContent>
                <div style={{display:"flex"}}>
                    <p>Title : </p>
                    <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        defaultValue="input title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />                    
                    <ColoredComboBox 
                        textList={pixelSizeList}
                        selectedText={pixelSize} 
                        onTextChange={setPixelSize} 
                        label={"Pixel Canvas Size :"}
                        width={"6rem"}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <>
                    <Button onClick={() => cbProjectSetting(title, pixelSize)} color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => cbProjectSetting('')} color="primary">
                        No
                    </Button>
                </>
            </DialogActions>
        </Dialog>
    );
};

export default NewProjectDialog;