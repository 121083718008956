import React, { useState, useEffect, useCallback } from 'react';

const CELL_SIZE = 30;
const GRID_SIZE = 20;

const DIRECTIONS = {
  UP: { x: 0, y: -1 },
  DOWN: { x: 0, y: 1 },
  LEFT: { x: -1, y: 0 },
  RIGHT: { x: 1, y: 0 },
};

const GameBattleCity = ({accountInfo}) => {
  const [playerTank, setPlayerTank] = useState({ x: 9, y: 18, direction: 'UP' });
  const [enemies, setEnemies] = useState([
    { x: 0, y: 0, direction: 'DOWN' },
    { x: 19, y: 0, direction: 'DOWN' },
    { x: 9, y: 0, direction: 'DOWN' },
  ]);
  const [bullets, setBullets] = useState([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);

  const movePlayer = useCallback((direction) => {
    setPlayerTank(prev => {
      const newPos = {
        x: prev.x + DIRECTIONS[direction].x,
        y: prev.y + DIRECTIONS[direction].y,
        direction,
      };
      if (newPos.x >= 0 && newPos.x < GRID_SIZE && newPos.y >= 0 && newPos.y < GRID_SIZE) {
        return newPos;
      }
      return { ...prev, direction };
    });
  }, []);

  const shoot = useCallback(() => {
    setBullets(prev => [
      ...prev,
      { ...playerTank, id: Date.now() }
    ]);
  }, [playerTank]);

  const moveEnemies = useCallback(() => {
    setEnemies(prev => prev.map(enemy => {
      const directions = Object.keys(DIRECTIONS);
      const newDirection = directions[Math.floor(Math.random() * directions.length)];
      const newPos = {
        x: enemy.x + DIRECTIONS[newDirection].x,
        y: enemy.y + DIRECTIONS[newDirection].y,
        direction: newDirection,
      };
      if (newPos.x >= 0 && newPos.x < GRID_SIZE && newPos.y >= 0 && newPos.y < GRID_SIZE) {
        return newPos;
      }
      return enemy;
    }));
  }, []);

  const moveBullets = useCallback(() => {
    setBullets(prev => prev.map(bullet => ({
      ...bullet,
      x: bullet.x + DIRECTIONS[bullet.direction].x,
      y: bullet.y + DIRECTIONS[bullet.direction].y,
    })).filter(bullet => 
      bullet.x >= 0 && bullet.x < GRID_SIZE && bullet.y >= 0 && bullet.y < GRID_SIZE
    ));
  }, []);

  const checkCollisions = useCallback(() => {
    bullets.forEach(bullet => {
      enemies.forEach((enemy, index) => {
        if (bullet.x === enemy.x && bullet.y === enemy.y) {
          setEnemies(prev => prev.filter((_, i) => i !== index));
          setBullets(prev => prev.filter(b => b.id !== bullet.id));
          setScore(prev => prev + 100);
        }
      });
    });

    enemies.forEach(enemy => {
      if (enemy.x === playerTank.x && enemy.y === playerTank.y) {
        setGameOver(true);
      }
    });
  }, [bullets, enemies, playerTank]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.key) {
        case 'ArrowUp':
          movePlayer('UP');
          break;
        case 'ArrowDown':
          movePlayer('DOWN');
          break;
        case 'ArrowLeft':
          movePlayer('LEFT');
          break;
        case 'ArrowRight':
          movePlayer('RIGHT');
          break;
        case ' ':
          shoot();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [movePlayer, shoot]);

  useEffect(() => {
    if (!gameOver) {
      const gameLoop = setInterval(() => {
        moveEnemies();
        moveBullets();
        checkCollisions();
      }, 200);

      return () => clearInterval(gameLoop);
    }
  }, [moveEnemies, moveBullets, checkCollisions, gameOver]);

  const renderCell = (x, y) => {
    if (playerTank.x === x && playerTank.y === y) {
      return '🚙';
    }
    if (enemies.some(enemy => enemy.x === x && enemy.y === y)) {
      return '🚜';
    }
    if (bullets.some(bullet => bullet.x === x && bullet.y === y)) {
      return '💥';
    }
    return '';
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Battle City</h1>
      <div style={{ display: 'inline-block', backgroundColor: '#f0f0f0', padding: '10px' }}>
        {Array.from({ length: GRID_SIZE }).map((_, y) => (
          <div key={y} style={{ display: 'flex' }}>
            {Array.from({ length: GRID_SIZE }).map((_, x) => (
              <div
                key={`${x}-${y}`}
                style={{
                  width: CELL_SIZE,
                  height: CELL_SIZE,
                  border: '1px solid #ccc',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                }}
              >
                {renderCell(x, y)}
              </div>
            ))}
          </div>
        ))}
      </div>
      <h2>Score: {score}</h2>
      {gameOver && <h2>Game Over!</h2>}
    </div>
  );
}

export default GameBattleCity;