import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

// type is a value of mui like success, error, secondary, and etc.
const ColoredMessageBox = ({ message, buttonType, onButtonClick, type }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    const handleButtonClick = (buttonType) => {
        if(onButtonClick)
            onButtonClick(buttonType);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ style: { backgroundColor: type } }}>
            <DialogTitle>Message</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {buttonType === 'OK' && (
                    <Button onClick={() => handleButtonClick('OK')} color="primary">
                        OK
                    </Button>
                )}
                {buttonType === 'YESNO' && (
                    <>
                        <Button onClick={() => handleButtonClick('YES')} color="primary">
                            Yes
                        </Button>
                        <Button onClick={() => handleButtonClick('NO')} color="primary">
                            No
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ColoredMessageBox;