import React from 'react';
import Spinner from './Core/Spinner';
import ColoredComboBox from './Core/ColoredComboBox';

const DownloadController = ({
    downloadType,
    selectedValue,
    startSec,
    onSetStartSec,
    endSec,
    onSetEndSec,
    comboTextList,
    onSelected,
}) => {
    return (
        <>
            <div style={{ display: 'flex' }}>
                <Spinner width={'5rem'} label={"Start(sec)"} value={startSec} onChangeValue={onSetStartSec} min={-1} max={100}/>
                <Spinner width={'5rem'} label={"End(sec)"} value={endSec} onChangeValue={onSetEndSec} min={-1} max={100}/>
                {
                    (downloadType === "mp4")? (
                        <ColoredComboBox 
                            textList={comboTextList} 
                            selectedText={selectedValue} 
                            onTextChange={onSelected}
                            label={"Select video resolution"}
                            width={"10rem"}
                        />
                    ) : (
                        <ColoredComboBox 
                            textList={comboTextList} 
                            selectedText={selectedValue} 
                            onTextChange={onSelected}
                            label={"Select audio Quaility"}
                            width={"10rem"}
                        />
                    )
                }
            </div>
        </>
    );
};

export default DownloadController;