import React, {useState, useRef} from 'react';
import axios from 'axios';
import { waitFor } from '../../utils/timer';
import CenteredText from '../../components/Core/CenteredText';
import Button from '@mui/material/Button';
import ColoredComboBox from '../../components/Core/ColoredComboBox';
import ColoredMessageBox from '../../components/Core/ColoredMessageBox';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import pica from 'pica';
import CircularProgress from '@mui/material/CircularProgress';

const supportedFormats = ['jpg', 'gif', 'bmp', 'tiff', 'png', 'webp'];

const ImageFormatConverter = (accountInfo) => {
    const [selectedFile, setSelectedFile]  =useState('')
    const [selectedImageFormat, setSelectedImageFormat] = useState('')
    const [targetFormat, setTargetFormat] = useState('png')
    const [imageSrc, setImageSrc] = useState('');
    const [imageCIS, setImageCIS] = useState();
    const [gifUrl, setGifUrl] = useState('');
    const canvasRef = useRef(null);
    const [errorMsgInfo, setErrorMsg] = useState(null)
    const [bWaiting, setWaiting] = useState(false)

    const handleCloseDlg = () => {
        setErrorMsg(null);
    }    
    // Add your component logic here
    const checkImageFormat = (file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (supportedFormats.includes(fileExtension)) {
            return fileExtension.toLowerCase()
        } else {
            return 'incorrect image format'
        }
    };

    const onSelectedTargetFormat = (value) => { 
        setTargetFormat(value);
        // convertImageFormat(selectedFile, event.target.value);
    }

    const convertImageFormat = async () => {
        console.log(`Converting image to ${targetFormat.toUpperCase()} format: ${selectedFile.name}`);

        setWaiting(true);
        if(imageCIS){
            const tempCanvas = document.createElement('canvas');        
            const tempContext = tempCanvas.getContext('2d');
            tempCanvas.width = imageCIS.naturalWidth;
            tempCanvas.height = imageCIS.naturalHeight;
            tempContext.drawImage(imageCIS, 0, 0);

            let dataUrl = ''
            const link = document.createElement('a');
            if(targetFormat === 'png'){
                dataUrl = tempCanvas.toDataURL(`image/png`);
            }else if(targetFormat === 'jpg'){
                dataUrl = tempCanvas.toDataURL(`image/jpeg`,1.0);
            }else if(targetFormat === 'webp'){
                const picaInstance = pica();
                const resizedCanvas = document.createElement('canvas');
                resizedCanvas.width = imageCIS.naturalWidth;
                resizedCanvas.height = imageCIS.naturalHeight;

                await picaInstance.resize(tempCanvas, resizedCanvas);
                const blob = await new Promise((resolve) => {
                  resizedCanvas.toBlob((blob) => {
                    resolve(blob);
                  }, 'image/webp');
                });
                dataUrl = URL.createObjectURL(blob);
            }else if(targetFormat === 'gif'){
                try {
                    const resDataUrl = await fetch(imageSrc);
                    const blob = await resDataUrl.blob();
        
                    const formData = new FormData();
                    formData.append('image_file', blob, selectedFile.name);
                    formData.append('keyId', accountInfo.uuid);
        
                    const response = await axios.post(`${process.env.REACT_APP_API_SERVER_ADDR}/api/converter/v1/togif`, formData, { 
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob', // Receive response as a blob
                    });
                    // const url = URL.createObjectURL(response.data);
                    // Create a URL for the blob and trigger download
                    dataUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'image/gif' }));
                } catch (error) {
                    setWaiting(false);
                    setErrorMsg({'msg':error, 'dlgType': 'OK', 'color': 'error'})
                    return;
                }
            }else if(targetFormat === 'tiff' || targetFormat === 'bmp'){
                try {
                    const resDataUrl = await fetch(imageSrc);
                    const blob = await resDataUrl.blob();
        
                    const formData = new FormData();
                    formData.append('image_file', blob, selectedFile.name);
                    formData.append('keyId', accountInfo.uuid);
                    formData.append('format', targetFormat);
        
                    const response = await axios.post(`${process.env.REACT_APP_API_SERVER_ADDR}/api/converter/v1/toimage`, formData, { 
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob', // Receive response as a blob
                    });
                    // const url = URL.createObjectURL(response.data);
                    // Create a URL for the blob and trigger download
                    dataUrl = window.URL.createObjectURL(new Blob([response.data], { type: `image/${targetFormat}` }));
                } catch (error) {
                    setWaiting(false);
                    setErrorMsg({'msg':error, 'dlgType': 'OK', 'color': 'error'})
                    return;
                }
            }else{
                setWaiting(false);
                setErrorMsg({'msg':'Unsupported image format.', 'dlgType': 'OK', 'color': 'error'})
                return;
            }
            await waitFor(1000);
            setWaiting(false);
            link.href = dataUrl;
            link.setAttribute('download', selectedFile.name.replace(/\.[^/.]+$/, `.${targetFormat}`));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
    }    

    const loadImage = (file) => {
        setSelectedFile(file)
        if(file){
            const reader = new FileReader();
            reader.onload = async (e) => {
                setImageSrc(e.target.result);
                const canvas = document.createElement('canvas');
                canvas.width = window.innerWidth* 0.3;
                canvas.height = window.innerWidth* 0.3;

                const image = new Image();
                image.src = e.target.result;
                image.onload = () => {
                    setImageCIS(image);
                    const canvas = canvasRef.current;
                    const ctx = canvas.getContext('2d');  //  willReadFrequently : canvas를 여러 번 읽을 때 GPU가 아닌 메모리를 사용해 처리. 속도가 더 빠르다.
                    canvas.width = image.naturalWidth;
                    canvas.height = image.naturalHeight;
                    let maxWidth =  window.innerWidth * 0.5;
                    if(canvas.width > maxWidth){
                        canvas.width = maxWidth;
                        canvas.height = maxWidth * (image.naturalHeight / image.naturalWidth);
                    }
                    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                };
            }
            reader.readAsDataURL(file);
        }
    }

    const handleFileChange = async (event) => {
        event.preventDefault();
        let image_ext = checkImageFormat(event.target.files[0])
        setSelectedImageFormat(image_ext)
        loadImage(event.target.files[0]);
    };

    //     // Add logic for handling file upload here
    //     const handleUpload = (event) => {
    //         const file = event.target.files[0];
    //         const reader = new FileReader();

    //         reader.onloadend = () => {
    //             const arrayBuffer = reader.result;
    //             const uint8Array = new Uint8Array(arrayBuffer);
    //             const bytes = [];

    //             uint8Array.forEach((byte) => {
    //                 bytes.push(byte.toString(16));
    //             });

    //             const hexString = bytes.join('').toUpperCase();

    //             // Check if the file is an image
    //             if (hexString.startsWith('FFD8') || hexString.startsWith('89504E47')) {
    //                 console.log('Uploaded file is an image');
    //                 checkImageFormat(file);
    //             } else {
    //                 console.log('Uploaded file is not an image');
    //             }
    //         };

    //         reader.readAsArrayBuffer(file);
    //     };
    // };

    return (
        <div>
            <header className="App-header">
                <CenteredText 
                    text="Convert image formats"
                    fontSize="1.5em"
                    fontColor="black"
                    color="0xff00ee"
                    fontFamily="BlackHanSans-Regular"
                    textAlign="center"
                />
                <div style={{display:"flex"}}>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ m: 2}}
                    >
                    Select image file
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange} accept="image/*"
                    />
                    </Button>
                    {imageCIS && <ColoredComboBox 
                        textList={supportedFormats} 
                        selectedText={targetFormat} 
                        onTextChange={onSelectedTargetFormat}
                        label={"Select Image Format"}
                        width={"10rem"}
                    />}

                    {(selectedImageFormat != targetFormat) && <Button onClick={() => convertImageFormat()}><SaveAltIcon /></Button>}

                </div>

                <div style={{position: "relative"}}>
                    <canvas ref={canvasRef} />
                    {gifUrl && <img src={gifUrl} alt="Converted GIF" />}
                    {bWaiting && <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 9999}}></div>}
                    {bWaiting && <CircularProgress style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}} />}
                </div>
            </header>
            {errorMsgInfo && <ColoredMessageBox message={errorMsgInfo.msg}  buttonType={errorMsgInfo.dlgType} onButtonClick={handleCloseDlg} type={errorMsgInfo.color}/>}
            
        </div>
    );
};

export default ImageFormatConverter;