import React, { useEffect } from 'react';
import YouTube from 'react-youtube';

function YoutubePlayer({ videoId, videoUrl, cbVideoInfo }) {
  const opts = {
    height: '200rem',
    width: '300rem',
    playerVars: {
      autoplay: 1,
      // 다양한 옵션들을 설정할 수 있습니다.
      // 자세한 내용은 https://developers.google.com/youtube/player_parameters 참고
    },
  };

  useEffect(()=> {
    checkVideoInfo()
  }, [])

  const checkVideoInfo = async () => {
    // const response = await axios.get(videoUrl, { responseType: 'blob' }); CORS로 막혀 있음.
    // console.log(response);
  }


  return (
    <YouTube
      videoId={videoId} // 유튜브 링크의 끝부분에 있는 고유한 아이디
      opts={opts}
      // 이벤트 리스너를 추가할 수 있습니다.
      onEnd={(e) => {
        e.target.stopVideo(0); // 동영상이 끝나면 처음 화면으로 돌아갑니다.
      }}
    />
  );
}

export default YoutubePlayer;
