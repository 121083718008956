import React, { useState } from 'react';
import ColoredComboBox from './ColoredComboBox';
import TextField from '@mui/material/TextField';

const InputComboInline = ({
    inputColor,
    inputWidth,
    inputFontSize,
    inputFontColor,
    inputFontFamily,
    buttonColor,
    buttonFontSize,
    buttonFontColor,
    buttonFontFamily,
    selectedTextValue,
    comboTextList,
    onInputChange,
    onSelected
}) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onInputChange(newValue); // Pass the value to the parent component
    };

    return (
        <div>
            <TextField
                required
                label="Enter video address"
                value={inputValue}
                sx={{ m: 2, width: {inputWidth} }}
                onChange={handleInputChange}
            />

            <ColoredComboBox 
                textList={comboTextList} 
                selectedText={selectedTextValue} 
                onTextChange={onSelected} 
                label={"File Type"}
                width={"6rem"}
            />
        </div>
    );
};

export default InputComboInline;
