import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { pixelizeCanvasRef } from '../utils/pixel';
import * as ColorTool from '../helpers/colorTool';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import SaveIcon from '@mui/icons-material/Save';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const useStyles = styled('div')({
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: 'max-content',
    },
    colorpickerButton: {
      margin: 6,
    },
});

const getColorText = (color, disablePlainColor) => {
    let text = disablePlainColor ? `color-${color.hex}` : color.name;
    if (text.startsWith('color-')) {
      if (typeof color.raw !== 'string' || !color.raw.startsWith('#')) {
        text = ColorTool.getCssColor(color, 'hex');
      } else {
        text = color.raw;
      }
    } else if (text === 'none') {
      text = color.raw;
    }
    return text;
};

function ImageEditor({ imageCIS, resolution, cbSave, cbSaveInLocal }) {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const canvasRef = useRef(null);
    const [bDrawEditingCanvas, setDrawEditingCanvas] = useState(false)
    const editingCanvasRatio = (isMobile)?0.5:0.3;
    const maxWidth = Math.pow(2, Math.ceil(Math.log2(window.innerWidth * editingCanvasRatio)));
    const [selectedColor, setSelectedColor] = useState('#ff0000')
    const selectedColoRef = useRef(selectedColor);
    selectedColoRef.current = selectedColor;

    const [points, setPoints] = useState([]);
    const pointsRef = useRef(points);
    pointsRef.current = points;

    const [redoStack, setRedoStack] = useState([]);
    let lastEditPoints = new Set();
    var isDragging = false;

    const handleCanvasDragEnd = () => {
        // Code to execute when dragging is finished
        lastEditPoints.clear();
        isDragging = false;
    };
    const handleCanvasDrag = (event) => {
        if (event.buttons !== 1) return; // Only draw when left mouse button is pressed
        const rect = canvasRef.current.getBoundingClientRect();
        const realGridSize = rect.width / resolution;
        const rx = event.clientX - rect.left;
        const ry = event.clientY - rect.top;
        const gridX = Math.floor(rx / realGridSize);
        const gridY = Math.floor(ry / realGridSize);
        const x = gridX * realGridSize;
        const y = gridY * realGridSize;
        const currentState = {
            x,
            y,
            color: selectedColoRef.current
        };

        if(lastEditPoints.length === lastEditPoints.add(JSON.stringify(currentState))) {
            return;
        }
        
        let copiedPoints =[...pointsRef.current];

        if(isDragging === false){
            copiedPoints.push(new Set([JSON.stringify(currentState)]));
            //  set 생성자는 iterable 객체를 받아들이므로, Set 생성자에 문자열만 넣으면 문자열이 iterable 객체라 각 문자열이 element로 들어간다.
            //  이를 방지하기 위해 []로 감싸서 Set 생성자에 넣어준다.
        }else{
            if(copiedPoints.length){
                if(copiedPoints[copiedPoints.length - 1].has(JSON.stringify(currentState)) === false){
                    copiedPoints[copiedPoints.length - 1].add(JSON.stringify(currentState));
                }
            }
        }
        isDragging = true
        setPoints(copiedPoints);
        setRedoStack([]);
    };

    const handleCanvasClick = (event) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const realGridSize = rect.width / resolution;
        const rx = event.clientX - rect.left;
        const ry = event.clientY - rect.top;
        const gridX = Math.floor(rx / realGridSize)
        const gridY = Math.floor(ry / realGridSize)
        const x = gridX * realGridSize;
        const y = gridY * realGridSize;
        const currentState = {
            x,
            y,
            color: selectedColor
        };
        let strCurrentState = JSON.stringify(currentState)
        setPoints(prevPoints => {
            const updatedPoints = [...prevPoints];
            // dragging의 마지막에 click 이벤트가 발생한다. 왜??!!!!
            if(updatedPoints.length && updatedPoints[updatedPoints.length - 1].has(strCurrentState))
                return updatedPoints;
            updatedPoints.push(new Set());
            updatedPoints[updatedPoints.length - 1].add(strCurrentState);
            return updatedPoints;
        });
        setRedoStack([]);
        lastEditPoints.clear();
        isDragging = false;
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });  //  willReadFrequently : canvas를 여러 번 읽을 때 GPU가 아닌 메모리를 사용해 처리. 속도가 더 빠르다.
        const gridSize = Math.floor(maxWidth / resolution);

        canvas.width = maxWidth;
        canvas.height = maxWidth;

        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        (imageCIS) && ctx.drawImage(imageCIS, 0, 0, canvas.width, canvas.height);
        pixelizeCanvasRef(canvas, ctx, gridSize);
    }, [imageCIS, resolution])

    useEffect(() => {
        setDrawEditingCanvas(true);
        const canvas = canvasRef.current;
        canvas.addEventListener('mousemove', handleCanvasDrag);
        canvas.addEventListener('mouseup', handleCanvasDragEnd);
        canvas.addEventListener('mouseleave', handleCanvasDragEnd);

        return () => {
            canvas.removeEventListener('mouseup', handleCanvasDragEnd);
            canvas.removeEventListener('mouseleave', handleCanvasDragEnd);
            canvas.removeEventListener('mousemove', handleCanvasDrag);
        };
    }, [canvasRef.current]);

    useEffect(() => {
        const gridSize = Math.floor(maxWidth / resolution);

        const ctx = canvasRef.current.getContext('2d');
        points.forEach(point => {
            for(let value of point){
                const pnt = JSON.parse(value);
                const { x, y, color } = pnt;
                    
                ctx.fillStyle = color;
                ctx.fillRect(x, y, gridSize, gridSize);
            }
        });
        
    }, [points])

    const ResetImage = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });  //  willReadFrequently : canvas를 여러 번 읽을 때 GPU가 아닌 메모리를 사용해 처리. 속도가 더 빠르다.
        const gridSize = Math.floor(maxWidth / resolution);
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(imageCIS, 0, 0, canvas.width, canvas.height);
        pixelizeCanvasRef(canvas, ctx, gridSize);
    }

    const Undo = () => {
        if(points.length <= 0)
            return;
        const lastPoint = points[points.length - 1];
        setRedoStack(prevRedoStack => [...prevRedoStack, lastPoint]);
        setPoints(prevPoints => prevPoints.slice(0, -1));
        ResetImage();
    }

    const Redo = () => {
        if(!redoStack || redoStack.length === 0)
            return;
        const lastRedoPoint = redoStack[redoStack.length - 1];
        setPoints(prevPoints => [...prevPoints, lastRedoPoint]);
        setRedoStack(prevRedoStack => prevRedoStack.slice(0, -1));
        ResetImage();
    }

    const Save = (bDirect) => {
        if(bDirect){
            cbSaveInLocal(canvasRef.current.toDataURL('image/png'));
        }else{
            cbSave( canvasRef.current.toDataURL('image/png'));
        }
    }

    return (
        <div>
            <canvas ref={canvasRef} width={maxWidth} onClick={handleCanvasClick}/>
            {bDrawEditingCanvas && (
            <div>
                <Box display="flex" justifyContent="flex-end">
                    {points.length>0 ? (<Button onClick={()=> Undo()} ><UndoIcon /></Button>):(<Button onClick={()=> Undo()} disabled><UndoIcon /></Button>)}
                    {redoStack.length>0 ? (<Button onClick={()=> Redo()} ><RedoIcon /></Button>):(<Button onClick={()=> Undo()} disabled><RedoIcon /></Button>)}
                    <input type="color" value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} />
                    <Button onClick={() => Save(false)}><SaveIcon /></Button>
                    <Button onClick={() => Save(true)}><SaveAltIcon /></Button>
                </Box>
            </div>
)}
        </div>
    );
}

export default ImageEditor;
