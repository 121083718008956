import React, {useEffect} from 'react';
import { pixelizeCanvasRef } from '../../utils/pixel';

const ImageFixedWidth = ({ imageSrc, imageCIS, bDrawGrid, resolution = 1 }) => {
    const canvasRef = React.useRef(null);
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const maxWidth = (isMobile) ? 64: 128;

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });  //  willReadFrequently : canvas를 여러 번 읽을 때 GPU가 아닌 메모리를 사용해 처리. 속도가 더 빠르다.
        if(resolution > maxWidth){
            resolution = maxWidth;
        }
        const gridSize = Math.floor(maxWidth / resolution);

        canvas.width = maxWidth;
        canvas.height = maxWidth;

        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        (imageCIS) && ctx.drawImage(imageCIS, 0, 0, canvas.width, canvas.height);
        pixelizeCanvasRef(canvas, ctx, gridSize);
    }, [imageCIS, resolution])    

    return (
        <div>
            <canvas ref={canvasRef} width={maxWidth} height={maxWidth} />
        </div>
    );
};

export default ImageFixedWidth;