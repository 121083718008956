// App.js
import React, { useRef, useState } from 'react';

const ImageCanvasButton = (props) => {
  const canvasRef = useRef(null);
  const [bSelected, setSelected] = useState(false);
  const {bgcolor, img} = props;

  const drawImageOnCanvas = () => {
    const selectFlag = !bSelected;
    setSelected(selectFlag);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = bgcolor
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    if(img){
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    }
    if(selectFlag){
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 5;
        ctx.strokeRect(0, 0, canvas.width, canvas.height);
    }
  }

  return (
    <div>
      <canvas onClick={drawImageOnCanvas} ref={canvasRef} width={128} height={128} style={{ border: '1px solid black' }} />
    </div>
  );
};

export default ImageCanvasButton;
