import './App.css';
import {getSavedUUIDData} from './utils/uuid';
import { useEffect, useState } from 'react';
import NavBar from './components/NavBar';
import Home from './pages/home';
import Png2Ico from './pages/converters/png2ico';
import ImageFormatConverter from './pages/converters/imageformat';
import PixelEditor from './pages/editors/pixeleditor';
import YoutubeTools from './pages/videotools/youtube';
import Snake from './pages/games/snake';
import GameArkanoid from './pages/games/arkanoid';
import GamePackman from './pages/games/packman';
import GameBattleCity from './pages/games/battlecity';

function App() {
  const [curPage, setCurPage] = useState('pixel editor');
  const [accountInfo, setAccountInfo] = useState(null);

  useEffect(()=> {
    var uuiddata  = getSavedUUIDData()
    setAccountInfo(uuiddata);
  },[])

  return (
    <div className="App">
        <NavBar onSetPage={setCurPage}  accountInfo={accountInfo} cbAccountInfo={setAccountInfo}/>
        {(curPage === 'home') && <Home  accountInfo={accountInfo}/>}
        {(curPage === 'png to ico') && <Png2Ico accountInfo={accountInfo}/>}
        {(curPage === 'image formats') && <ImageFormatConverter accountInfo={accountInfo}/>}
        {(curPage === 'pixel editor') && <PixelEditor accountInfo={accountInfo}/>}
        {(curPage === 'youtube to') && <YoutubeTools accountInfo={accountInfo}/>}
        {(curPage === 'snake') && <Snake accountInfo={accountInfo}/>}
        {(curPage === 'arkanoid') && <GameArkanoid accountInfo={accountInfo}/>}
        {(curPage === 'packman') && <GamePackman accountInfo={accountInfo}/>}
        {(curPage === 'battle city') && <GameBattleCity accountInfo={accountInfo}/>}
    </div>
  );
}

export default App;