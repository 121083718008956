import React, { useState, useEffect, useCallback } from 'react';

const CELL_SIZE = 20;
const ROWS = 20;
const COLS = 20;

const MAZE = [
  '####################',
  '#........#.........#',
  '#.##.###.#.###.##..#',
  '#.#...............#',
  '#.#.##.#####.##.#..#',
  '#....#...#...#.....#',
  '####.###.#.###.#####',
  '#....#.......#.....#',
  '#.##.#.#####.#.##..#',
  '#..#...#...#...#...#',
  '#.##.###.#.###.##..#',
  '#................P.#',
  '####################',
];

const DIRECTIONS = {
  ArrowUp: { x: 0, y: -1 },
  ArrowDown: { x: 0, y: 1 },
  ArrowLeft: { x: -1, y: 0 },
  ArrowRight: { x: 1, y: 0 },
};

const GamePackman = ({accountInfo}) => {
  const [pacman, setPacman] = useState({ x: 1, y: 1 });
  const [ghost, setGhost] = useState({ x: 18, y: 1 });
  const [score, setScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [maze, setMaze] = useState(MAZE.map(row => row.split('')));

  function restartGame(){
    setScore(0);
    setPacman({ x: 1, y: 1 });
    setGhost({ x: 18, y: 1 });
    setGameOver(false);
    setMaze(MAZE.map(row => row.split('')));
  }

  const moveCharacter = useCallback((character, direction) => {
    const newPos = {
      x: character.x + direction.x,
      y: character.y + direction.y,
    };

    if (maze[newPos.y][newPos.x] !== '#') {
      return newPos;
    }

    return character;
  }, [maze]);

  const movePacman = useCallback((direction) => {
    setPacman(prev => {
      const newPos = moveCharacter(prev, direction);
      if (maze[newPos.y][newPos.x] === '.') {
        setScore(prev => prev + 10);
        if(prev + 10 > maxScore){
          setMaxScore(prev + 10);
        }
        setMaze(prev => {
          const newMaze = prev.map(row => [...row]);
          newMaze[newPos.y][newPos.x] = ' ';
          return newMaze;
        });
      }
      return newPos;
    });
  }, [moveCharacter, maze]);

  const moveGhost = useCallback(() => {
    const directions = Object.values(DIRECTIONS);
    const randomDirection = directions[Math.floor(Math.random() * directions.length)];
    setGhost(prev => moveCharacter(prev, randomDirection));
  }, [moveCharacter]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (DIRECTIONS[e.key]) {
        movePacman(DIRECTIONS[e.key]);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [movePacman]);

  useEffect(() => {
    if (!gameOver) {
      const ghostInterval = setInterval(moveGhost, 500);
      return () => clearInterval(ghostInterval);
    }
  }, [moveGhost, gameOver]);

  useEffect(() => {
    if (pacman.x === ghost.x && pacman.y === ghost.y) {
      setGameOver(true);
    }
  }, [pacman, ghost]);

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Pac-Man Game</h1>
      <div style={{ display: 'inline-block' }}>
        {maze.map((row, y) => (
          <div key={y} style={{ display: 'flex' }}>
            {row.map((cell, x) => (
              <div
                key={`${x}-${y}`}
                style={{
                  width: CELL_SIZE,
                  height: CELL_SIZE,
                  backgroundColor: cell === '#' ? 'blue' : 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {pacman.x === x && pacman.y === y && (
                  <div style={{
                    width: CELL_SIZE * 0.8,
                    height: CELL_SIZE * 0.8,
                    backgroundColor: 'yellow',
                    borderRadius: '50%',
                  }} />
                )}
                {ghost.x === x && ghost.y === y && (
                  <div style={{
                    width: CELL_SIZE * 0.8,
                    height: CELL_SIZE * 0.8,
                    backgroundColor: 'red',
                    borderRadius: '50% 50% 0 0',
                  }} />
                )}
                {cell === '.' && (
                  <div style={{
                    width: CELL_SIZE * 0.2,
                    height: CELL_SIZE * 0.2,
                    backgroundColor: 'white',
                    borderRadius: '50%',
                  }} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <h2>Score: {score} / maxScore: {maxScore}</h2>
      {!gameOver && <button onClick={() => restartGame()}>Reset Game</button>}
      {gameOver && <h2>Game Over!</h2>}
    </div>
  );
}

export default GamePackman;
