import React, { useState, useEffect, useRef } from 'react';

const BOARD_WIDTH = 480;
const BOARD_HEIGHT = 360;
const PADDLE_WIDTH = 80;
const PADDLE_HEIGHT = 10;
const BALL_SIZE = 10;
const BRICK_ROWS = 3;
const BRICK_COLUMNS = 8;
const BRICK_WIDTH = 60;
const BRICK_HEIGHT = 20;

const GameArkanoid = ({accountInfo}) => {
  const [paddle, setPaddle] = useState({ x: BOARD_WIDTH / 2 - PADDLE_WIDTH / 2, y: BOARD_HEIGHT - PADDLE_HEIGHT - 10 });
  const [ball, setBall] = useState({ x: BOARD_WIDTH / 2, y: BOARD_HEIGHT / 2, dx: 2, dy: -2 });
  const [bricks, setBricks] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const canvasRef = useRef(null);
  const [score, setScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);

  function restartGame(){
    setScore(0);
    setPaddle({ x: BOARD_WIDTH / 2 - PADDLE_WIDTH / 2, y: BOARD_HEIGHT - PADDLE_HEIGHT - 10 });
    setBall({ x: BOARD_WIDTH / 2, y: BOARD_HEIGHT / 2, dx: 2, dy: -2 });
    setGameOver(false);
    const newBricks = [];
    for (let c = 0; c < BRICK_COLUMNS; c++) {
      for (let r = 0; r < BRICK_ROWS; r++) {
        newBricks.push({
          x: c * (BRICK_WIDTH + 1),
          y: r * (BRICK_HEIGHT + 1),
          status: 1
        });
      }
    }
    setBricks(newBricks);
  }
  useEffect(() => {
    const newBricks = [];
    for (let c = 0; c < BRICK_COLUMNS; c++) {
      for (let r = 0; r < BRICK_ROWS; r++) {
        newBricks.push({
          x: c * (BRICK_WIDTH + 1),
          y: r * (BRICK_HEIGHT + 1),
          status: 1
        });
      }
    }
    setBricks(newBricks);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft' && paddle.x > 0) {
        setPaddle(prev => ({ ...prev, x: prev.x - 20 }));
      }
      if (e.key === 'ArrowRight' && paddle.x < BOARD_WIDTH - PADDLE_WIDTH) {
        setPaddle(prev => ({ ...prev, x: prev.x + 20 }));
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [paddle]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const canvas = canvasRef.current;
      const canvasRect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - canvasRect.left;
      if (mouseX >= 0 && mouseX <= BOARD_WIDTH - PADDLE_WIDTH) {
        setPaddle(prev => ({ ...prev, x: mouseX }));
      }
    };

    const canvas = canvasRef.current;
    canvas.addEventListener('mousemove', handleMouseMove);
    return () => canvas.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const gameLoop = setInterval(() => {
      if (gameOver) return;

      ctx.clearRect(0, 0, BOARD_WIDTH, BOARD_HEIGHT);

      // Draw paddle
      ctx.fillStyle = '#0095DD';
      ctx.fillRect(paddle.x, paddle.y, PADDLE_WIDTH, PADDLE_HEIGHT);

      // Draw ball
      ctx.beginPath();
      ctx.arc(ball.x, ball.y, BALL_SIZE / 2, 0, Math.PI * 2);
      ctx.fillStyle = '#0095DD';
      ctx.fill();
      ctx.closePath();

      // Draw bricks
      bricks.forEach(brick => {
        if (brick.status === 1) {
          ctx.fillStyle = '#0095DD';
          ctx.fillRect(brick.x, brick.y, BRICK_WIDTH, BRICK_HEIGHT);
        }
      });

      // Move ball
      setBall(prev => ({
        ...prev,
        x: prev.x + prev.dx,
        y: prev.y + prev.dy
      }));

      // Ball collision with walls
      if (ball.x + ball.dx > BOARD_WIDTH - BALL_SIZE / 2 || ball.x + ball.dx < BALL_SIZE / 2) {
        setBall(prev => ({ ...prev, dx: -prev.dx }));
      }
      if (ball.y + ball.dy < BALL_SIZE / 2) {
        setBall(prev => ({ ...prev, dy: -prev.dy }));
      }

      // Ball collision with paddle
      if (
        ball.y + ball.dy > BOARD_HEIGHT - BALL_SIZE - PADDLE_HEIGHT &&
        ball.x > paddle.x &&
        ball.x < paddle.x + PADDLE_WIDTH
      ) {
        setBall(prev => ({ ...prev, dy: -prev.dy, dx: prev.dx + (ball.x - (paddle.x + PADDLE_WIDTH / 2)) / PADDLE_WIDTH }));
      }

      // Ball collision with bricks
      bricks.forEach((brick, index) => {
        if (brick.status === 1) {
          if (
            ball.x > brick.x &&
            ball.x < brick.x + BRICK_WIDTH &&
            ball.y > brick.y &&
            ball.y < brick.y + BRICK_HEIGHT
          ) {
            setBall(prev => ({ ...prev, dy: -prev.dy }));
            setBricks(prev => prev.map((b, i) => i === index ? { ...b, status: 0 } : b));
            setScore(prev => prev + 1);
            if(score + 1 > maxScore){
              setMaxScore(score + 1);
            }
          }
        }
      });

      // Game over condition
      if (ball.y + ball.dy > BOARD_HEIGHT - BALL_SIZE / 2) {
        setGameOver(true);
      }
    }, 10);

    return () => clearInterval(gameLoop);
  }, [ball, paddle, bricks, gameOver]);

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Arkanoid Game</h1>
      <h2>Score : {score} / Max : {maxScore} </h2>
      <canvas ref={canvasRef} width={BOARD_WIDTH} height={BOARD_HEIGHT} style={{ border: '1px solid black' }} />
      <br/>
      <button onClick={() => restartGame()}>Reset Game</button>
      {gameOver && <h2>Game Over!</h2>}
    </div>
  );
}

export default GameArkanoid;