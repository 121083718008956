import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button'
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import CircularProgress from '@mui/material/CircularProgress';
import CreateIcon from '@mui/icons-material/Create';
import DrawIcon from '@mui/icons-material/Draw';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import FiberNewIcon from '@mui/icons-material/FiberNew';

import CenteredText from '../../components/Core/CenteredText';
import ColoredMessageBox from '../../components/Core/ColoredMessageBox';
import NewProjectDialog from '../../components/NewProjectDialog';
// import { waitFor } from '../../utils/timer';
import ScrollableContainer from '../../components/Core/ScrollableContainer';
import { pixelizeCanvasRef, getBresenhamLinePoints, drawBresenhamLine } from '../../utils/pixel';
import { truncateString } from '../../utils/string';
import * as peClass from '../../classes/pixeleditorClasses';
import {useAtom} from 'jotai';


const PixelEditor = ({accountInfo}) => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const canvasRef = useRef(null);
    const resolution = 256; //  화면 기본 해상도 - 지원 최대 해상도로..

    const [pixelProject, setPixelProject] = useAtom(peClass.pixelProjectAtom);
    const [pixelProjectName] = useAtom(peClass.getPixelProjectName);
    const [, setPixelProjectName] = useAtom(peClass.setPixelProjectName);
    const [pixelProjectSize] = useAtom(peClass.getPixelProjectWidth);
    const pixelSizeRef = useRef(pixelProjectSize);
    pixelSizeRef.current = pixelProjectSize;
    const [pixelProjectFrames] = useAtom(peClass.getPixelProjectFrames);
    const [, setPixelProjectFrames] = useAtom(peClass.setPixelProjectFrames);
    const [posString, setPosString] = useState('');

    const [selectedFile, setSelectedFile]  =useState('')
    const [errorMsgInfo, setErrorMsg] = useState(null)
    const [okcancelMsgInfo, setOkcancelMsgInfo] = useState(null)
    const [showProjectSettingDlg, setShowProjectSettingDlg] = useState(false)

    const [bDrawEditingCanvas, setDrawEditingCanvas] = useState(false)
    // const editingCanvasRatio = (isMobile)?0.5:0.3;
    const editingCanvasRatio = 0.3;
    const maxScreenWH = window.innerWidth > 256*2 ? (256*2) : 256;
    const [selectedColor, setSelectedColor] = useState('#ff0000')
    const selectedColoRef = useRef(selectedColor);
    selectedColoRef.current = selectedColor;

    const [points, setPoints] = useState([]);
    const pointsRef = useRef(points);
    pointsRef.current = points;

    const [redoStack, setRedoStack] = useState([]);
    let lastEditPoints = new Set();
    var isDragging = false;

    const [selectedMenu, setSelectedMenu] = useState('pencil'); //  pencil, eraser, fill, selectArea
    const selectMenuRef = useRef(selectedMenu);
    selectMenuRef.current = selectedMenu;

    const handleMenuChange = (event, nextMenu) => {
        setSelectedMenu(nextMenu);
    };

    useEffect(() => {
        resetBy()
    },[]);

    const resetBy = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        canvas.width = resolution*((isMobile)?1:2);
        canvas.height = resolution*((isMobile)?1:2);
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        setPoints([]);
        setRedoStack([]);
    }

    const handleCloseDlg = () => {
        setErrorMsg(null);
    }

    const ResetImage = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });  //  willReadFrequently : canvas를 여러 번 읽을 때 GPU가 아닌 메모리를 사용해 처리. 속도가 더 빠르다.
        const gridSize = Math.floor(maxScreenWH / pixelSizeRef.current);
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        pixelizeCanvasRef(canvas, ctx, gridSize);
    }

    const Undo = () => {
        if(points.length <= 0)
            return;
        const lastPoint = points[points.length - 1];
        ResetImage()
        setRedoStack(prevRedoStack => [...prevRedoStack, lastPoint]);
        setPoints(prevPoints => prevPoints.slice(0, -1));
    }

    const Redo = () => {
        if(!redoStack || redoStack.length === 0)
            return;
        const lastRedoPoint = redoStack[redoStack.length - 1];
        ResetImage()
        setPoints(prevPoints => [...prevPoints, lastRedoPoint]);
        setRedoStack(prevRedoStack => prevRedoStack.slice(0, -1));
    }

    const Save = () => {
        const dataUrl = canvasRef.current.toDataURL('image/png'); 
        const image = new Image();
        image.src = dataUrl
        image.onload = () => {
            const tempCanvas = document.createElement('canvas');
            const tempContext = tempCanvas.getContext('2d', { willReadFrequently: true });
            tempCanvas.width = pixelSizeRef.current;
            tempCanvas.height = pixelSizeRef.current;
            tempContext.drawImage(image, 0, 0, pixelSizeRef.current, pixelSizeRef.current);
            const dataUrl = tempCanvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = pixelProjectName + '.png';
            link.click();
        };
        image.onerror = (err) => {
            setErrorMsg({msg: 'Failed to save the image. ' + err, dlgType: 'OK', color: 'error'});
        }
    }

    const onNewProject = () => {
        setOkcancelMsgInfo({msg:'Did you save the data you are working on?', dlgType: 'YESNO', color: 'warning'})
    }
    const onNewProjectSetting = (result) => {
        if(result === 'NO'){
            setOkcancelMsgInfo(null);
            return;
        }
        setOkcancelMsgInfo(null);
        setShowProjectSettingDlg(true);
    }
    const handleProjectSetting = (title, pixelSize) => {
        setShowProjectSettingDlg(false);
        if(title){
            //  이렇게 동시에 여러 속성을 변경하려고 하면 
            //  setPixelProjectName의 속성 변경 후 setPixelProjectSize의 속성 변경이 무시된다.
            //  완전 무시는 다니고 다음에 변경할 때 pixelSize가 이전에 설정한 값이 설정됨.
            //  데이터 관리의 다른 운영이 필요하다.
            //  값을 한번에 바꾼다던가 해야 함. 따로 따로 말고..
            //  속성을 하나씩 바꿀때는 이슈가 없음.
            //  initProject(title, pixelSize); 이런식으로 인터페이스 만들자.

            setPixelProjectName(title, pixelSize)
            resetBy();
        }
    }

    const onAddFrame = () => {
        //  add empty frame
        const newFrame = new peClass.pixelFrame();
        setPixelProjectFrames([...pixelProjectFrames, newFrame]);
    }
    const onDuplicateFrame = () => {
        
    }
    const onRemoveFrame = () => {
        
    }

    const handleCanvasDragEnd = () => {
        // Code to execute when dragging is finished
        lastEditPoints.clear();
        isDragging = false;
    };
    const handleCanvasDrag = (event) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const realGridSize = rect.width / pixelSizeRef.current;
        const rx = event.clientX - rect.left;
        const ry = event.clientY - rect.top;
        const gridX = Math.floor(rx / realGridSize);
        const gridY = Math.floor(ry / realGridSize);

        setPosString(`(${gridX.toString().padStart(3, '0')}, ${gridY.toString().padStart(3, '0')})`); 
        if (event.buttons !== 1) return; // Only draw when left mouse button is pressed
        if(selectMenuRef.current !== 'pencil' && selectMenuRef.current !== 'eraser'){
            return;
        }

        const x = gridX * realGridSize;
        const y = gridY * realGridSize;
        const currentState = new peClass.pixelState(gridX, gridY, (selectMenuRef.current === 'eraser'), (selectMenuRef.current === 'eraser' ? ('#000000'):(selectedColoRef.current)), selectMenuRef.current, 1);

        if(lastEditPoints.length === lastEditPoints.add(JSON.stringify(currentState))) {
            return;
        }
        
        let copiedPoints =[...pointsRef.current];

        if(isDragging === false){
            copiedPoints.push(new Set([JSON.stringify(currentState)]));
            //  set 생성자는 iterable 객체를 받아들이므로, Set 생성자에 문자열만 넣으면 문자열이 iterable 객체라 각 문자열이 element로 들어간다.
            //  이를 방지하기 위해 []로 감싸서 Set 생성자에 넣어준다.
        }else{
            if(copiedPoints.length){
                if(copiedPoints[copiedPoints.length - 1].has(JSON.stringify(currentState)) === false){
                    const setData = Array.from(copiedPoints[copiedPoints.length - 1]);
                    let lastPos = JSON.parse(setData[setData.length-1]);
                    if(Math.abs(gridX - lastPos.x)>1 || Math.abs(gridY - lastPos.y)>1){
                        let points = getBresenhamLinePoints(lastPos.x, lastPos.y, gridX, gridY, 1);
                        points.forEach(point => {
                            let pnt = new peClass.pixelState(point.x, point.y, (selectMenuRef.current === 'eraser'), (selectMenuRef.current === 'eraser' ? ('#000000'):(selectedColoRef.current)), selectMenuRef.current, 1);
                            copiedPoints[copiedPoints.length - 1].add(JSON.stringify(pnt));
                        });
                        
                    }else{
                        copiedPoints[copiedPoints.length - 1].add(JSON.stringify(currentState));
                    }
                }
            }
        }
        isDragging = true
        setPoints(copiedPoints);
        setRedoStack([]);
    };

    const handleCanvasClick = (event) => {
        if(selectedMenu === 'pencil')
            menuDraw(event);
        else if(selectedMenu === 'eraser')
            menuErase(event);
        else if(selectedMenu === 'fill')
            menuFill(event);
        // else if(selectedMenu === 'selectArea')
        //     menuSelectArea(event);
    };

    const menuDraw = (event) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const realGridSize = rect.width / pixelSizeRef.current;
        const rx = event.clientX - rect.left;
        const ry = event.clientY - rect.top;
        const gridX = Math.floor(rx / realGridSize)
        const gridY = Math.floor(ry / realGridSize)
        const x = gridX * realGridSize;
        const y = gridY * realGridSize;
        const currentState = new peClass.pixelState(gridX, gridY, false,selectedColoRef.current, selectMenuRef.current, 1);
        let strCurrentState = JSON.stringify(currentState)
        setPoints(prevPoints => {
            const updatedPoints = [...prevPoints];
            // dragging의 마지막에 click 이벤트가 발생한다. 왜??!!!!
            if(updatedPoints.length && updatedPoints[updatedPoints.length - 1].has(strCurrentState))
                return updatedPoints;
            updatedPoints.push(new Set());
            updatedPoints[updatedPoints.length - 1].add(strCurrentState);
            return updatedPoints;
        });
        setRedoStack([]);
        lastEditPoints.clear();
        isDragging = false;
    }

    const menuErase = (event) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const realGridSize = rect.width / pixelSizeRef.current;
        const rx = event.clientX - rect.left;
        const ry = event.clientY - rect.top;
        const gridX = Math.floor(rx / realGridSize)
        const gridY = Math.floor(ry / realGridSize)
        const x = gridX * realGridSize;
        const y = gridY * realGridSize;
        const currentState = new peClass.pixelState(gridX, gridY, true, '#000000', selectMenuRef.current, 1);

        let strCurrentState = JSON.stringify(currentState)
        setPoints(prevPoints => {
            const updatedPoints = [...prevPoints];
            // dragging의 마지막에 click 이벤트가 발생한다. 왜??!!!!
            if(updatedPoints.length && updatedPoints[updatedPoints.length - 1].has(strCurrentState))
                return updatedPoints;
            updatedPoints.push(new Set());
            updatedPoints[updatedPoints.length - 1].add(strCurrentState);
            return updatedPoints;
        });
        setRedoStack([]);
        lastEditPoints.clear();
        isDragging = false;
    }
    
    const hexToRgb = (hex) => {
        const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => {
            return '#' + r + r + g + g + b + b;
        }).substring(1).match(/.{2}/g).map(x => parseInt(x, 16));
        return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
    };

    const rgbToHex = (rgb) => {
        const [r, g, b] = rgb.substring(4, rgb.length - 1).split(',').map(x => parseInt(x.trim()));
        return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };

    const fillConnectedPoints = (x, y, color) => {
        const visited = [];
        const queue = [[x, y]];
        const boundary = [];
        const rgbColor = rgbToHex(color);
        //  canvas의 색을 따지지 말고, 
        //  frame 에 저장된 pixel Color를 확인하는 것으로 아래 코드를 바꾸자.
        let count = 0;
        let copiedPoints =[...pointsRef.current];
        while (queue.length > 0) {
            const [currX, currY] = queue.shift();
            // Find the point in copiedPoints that matches currX and currY
            let matchingPoint = undefined;
            for (let i = copiedPoints.length-1; i >= 0 ; i--) {
                const arr = Array.from(copiedPoints[i])
                const point = arr.find(point => {
                    point = JSON.parse(point);
                    if(point.x === currX && point.y === currY){
                        return point;
                    }
                });
                if (point !== undefined) {
                    matchingPoint = JSON.parse(point);
                    break;
                }
            } 
            // Convert the color value to RGB

            // Check if the current point is already visited or has a different color
            if(!visited.some(point => point[0] === currX && point[1] === currY) && (rgbColor !== "#000000" && matchingPoint === undefined)){
                continue;
            }else
            if (visited.some(point => point[0] === currX && point[1] === currY) || (matchingPoint !==undefined && matchingPoint.color !== rgbColor))
            {
                continue;
            }else 
            if(matchingPoint !== undefined && matchingPoint.color === undefined){
                continue;
            }

            // Mark the current point as visited
            visited.push([currX, currY]);
            // Process the neighboring points
            const neighbors = [];
            if(currX - 1 >=0)
                neighbors.push([currX - 1, currY]); // Left
            if(currX + 1 < pixelSizeRef.current)
                neighbors.push([currX + 1, currY]); // Right
            if(currY - 1 >=0)
                neighbors.push([currX, currY - 1]); // Top
            if(currY + 1 < pixelSizeRef.current)
                neighbors.push([currX, currY + 1]); // Bottom

            for (const [nx, ny] of neighbors) {
                if (!visited.some(point => point[0] === nx && point[1] === ny)) {
                    queue.push([nx, ny]);
                }
            }
            ++count;
        }
        return visited;
    };

    const getPixelColor = (x, y) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const pixelData = ctx.getImageData(x, y, 1, 1).data;
        const [r, g, b] = pixelData;
        return `rgb(${r}, ${g}, ${b})`;
    };

    const menuFill = (event) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const realGridSize = rect.width / pixelSizeRef.current;
        const rx = event.clientX - rect.left;
        const ry = event.clientY - rect.top;
        const gridX = Math.floor(rx / realGridSize);
        const gridY = Math.floor(ry / realGridSize);
        const scrx = gridX * realGridSize;
        const scry = gridY * realGridSize;
        const tolerance = 32; // 색상 허용 오차

        const color = getPixelColor(scrx, scry);
        const connectedPoints = fillConnectedPoints(gridX, gridY, color);
        if(connectedPoints.length > 0){
            setPoints(prevPoints => {
                const updatedPoints = [...prevPoints];
                updatedPoints.push(new Set());
                for (const [x, y] of connectedPoints) {
                    const currentState = new peClass.pixelState(x, y, false, selectedColoRef.current, selectMenuRef.current, 1);
                    let strCurrentState = JSON.stringify(currentState)                    
                    updatedPoints[updatedPoints.length - 1].add(strCurrentState);
                }
                return updatedPoints;
            });
        }
    };
    useEffect(() => {
        const gridSize = Math.floor(maxScreenWH / pixelSizeRef.current);
        const canvas = canvasRef.current;
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        //    테두리 그리기
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.strokeRect(0, 0, canvas.width, canvas.height);
        
        let lastPoint = null;
        let offset = null;
        points.forEach(point => {
            for(let pnt of point){
                pnt = JSON.parse(pnt);
                if(lastPoint){
                    // if( pnt.brushType === 'pencil' && point.size >= 4){
                    //     drawCatmullRomSpline(ctx, point, pnt.color, pnt.brushSize*gridSize);
                    //     // drawBezierCurve(ctx, lastPoint, pnt, pnt.color, pnt.brushSize);
                    // }else
                    if(pnt.brushType === 'pencil') 
                    {
                        ctx.strokeStyle = pnt.color;
                        ctx.beginPath();
                        ctx.fillRect(pnt.x*gridSize, pnt.y*gridSize, gridSize, gridSize);
                        // drawBresenhamLine(ctx, lastPoint.x*gridSize, lastPoint.y*gridSize, pnt.x*gridSize, pnt.y*gridSize, pnt.brushSize * gridSize);
                        ctx.stroke();
                    }
                    else if(pnt.brushType === 'eraser'){
                        ctx.strokeStyle = 'white';
                        ctx.beginPath();
                        ctx.clearRect(pnt.x*gridSize, pnt.y*gridSize, gridSize, gridSize);
                        // drawBresenhamLine(ctx, lastPoint.x*gridSize, lastPoint.y*gridSize, pnt.x*gridSize, pnt.y*gridSize, pnt.brushSize * gridSize, true);
                        //  점과 점 사이를 이어주는 거라 그 사이의 값은 그대로 이전 컬러값이 남아 있는 거임. 어?? 왜 pencil은 그렇지 않지? 내일은 놀지말고 원인 찾아라!
                        ctx.stroke();
                    }else if(pnt.brushType === 'fill'){
                        ctx.fillStyle = pnt.color;
                        ctx.fillRect(pnt.x*gridSize, pnt.y*gridSize, gridSize, gridSize);
                    }
                }else{
                    if(pnt.brushType === 'pencil') {
                        ctx.fillStyle = pnt.color;
                        ctx.fillRect(pnt.x*gridSize, pnt.y*gridSize, gridSize, gridSize);
                        offset = pnt;
                    }else if(pnt.brushType === 'eraser'){
                        ctx.fillStyle = 'white';
                        ctx.clearRect(pnt.x*gridSize, pnt.y*gridSize, gridSize, gridSize);
                        offset = pnt;
                    }else if(pnt.brushType === 'fill'){
                        ctx.fillStyle = pnt.color;
                        ctx.fillRect(pnt.x*gridSize, pnt.y*gridSize, gridSize, gridSize);
                    }
                }
                lastPoint = pnt;
            }
            lastPoint = null
        });
        
    }, [points])    

    useEffect(() => {
        setDrawEditingCanvas(true);
        const canvas = canvasRef.current;
        canvas.addEventListener('mousemove', handleCanvasDrag);
        canvas.addEventListener('mouseup', handleCanvasDragEnd);
        canvas.addEventListener('mouseleave', handleCanvasDragEnd);

        return () => {
            canvas.removeEventListener('mouseup', handleCanvasDragEnd);
            canvas.removeEventListener('mouseleave', handleCanvasDragEnd);
            canvas.removeEventListener('mousemove', handleCanvasDrag);
        };
    }, [canvasRef.current]);    

    return (
        <div>
            <header className="App-header">
                <CenteredText 
                    text={`Pixel Editor - [${truncateString(pixelProjectName)} / ${pixelSizeRef.current}pixel]`}
                    fontSize="1.5em"
                    fontColor="black"
                    color="0xff00ee"
                    fontFamily="BlackHanSans-Regular"
                    textAlign="center"
                />
                { (pixelProject.frames.length>0) && <ScrollableContainer />}
                <Box display="flex" justifyContent="flex-end">
                    <Tooltip title="new project"><Button onClick={() => onNewProject(false)}><FiberNewIcon /></Button></Tooltip>
                    {points.length>0 ? (<Tooltip title="undo"><Button onClick={()=> Undo()} ><UndoIcon /></Button></Tooltip>):(<Button onClick={()=> Undo()} disabled><UndoIcon /></Button>)}
                    {redoStack.length>0 ? (<Tooltip title="redo"><Button onClick={()=> Redo()} ><RedoIcon /></Button></Tooltip>):(<Button onClick={()=> Undo()} disabled><RedoIcon /></Button>)}
                    {/**<Tooltip title="add frame"><Button onClick={() => onAddFrame(false)}><AddIcon /></Button></Tooltip>*/}
                    {/* <Tooltip title="duplicate current frame"><Button onClick={() => onDuplicateFrame(false)}><ContentCopyIcon /></Button></Tooltip> */}
                    {/* <Tooltip title="remove current frame"><Button onClick={() => onRemoveFrame(false)}><DeleteIcon /></Button></Tooltip> */}
                    <Tooltip title="Download current frame"><Button onClick={() => Save(true)}><SaveAltIcon /></Button></Tooltip>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <canvas ref={canvasRef} width={maxScreenWH} onClick={handleCanvasClick}/>
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={selectedMenu}
                        exclusive
                        onChange={handleMenuChange}
                        style={{marginLeft: '10px'}}    
                    >
                        <Tooltip title="select color"><input type="color" value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} /></Tooltip>
                        <Tooltip title="pencil"><ToggleButton value="pencil" aria-label="pencil">
                            <CreateIcon />
                        </ToggleButton></Tooltip>
                        <Tooltip title="eraser"><ToggleButton value="eraser" aria-label="eraser">
                            <EditOffIcon />
                        </ToggleButton></Tooltip>
                        <Tooltip title="fill"><ToggleButton value="fill" aria-label="fill">
                            <FormatColorFillIcon />
                        </ToggleButton></Tooltip>
                        {/* <Tooltip title="select area"><ToggleButton value="selectArea" aria-label="selectArea">
                            <HighlightAltIcon />
                        </ToggleButton></Tooltip> */}
                        <Tooltip title="select area" style={{fontSize:"0.5em"}}><p>{posString}</p></Tooltip>
                        

                    </ToggleButtonGroup>
                </Box>
                {okcancelMsgInfo && <ColoredMessageBox message={okcancelMsgInfo.msg}  buttonType={okcancelMsgInfo.dlgType} onButtonClick={onNewProjectSetting} type={okcancelMsgInfo.color}/>}
                {showProjectSettingDlg && <NewProjectDialog cbProjectSetting={handleProjectSetting}/>}
                {errorMsgInfo && <ColoredMessageBox message={errorMsgInfo.msg}  buttonType={errorMsgInfo.dlgType} onButtonClick={handleCloseDlg} type={errorMsgInfo.color}/>}
            </header>
        </div>
    );
};

export default PixelEditor;
