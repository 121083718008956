// ScrollableContainer.js
import React from 'react';
import ImageCanvasButton from './ImageCanvasButton';
import './ScrollableContainer.css';

const ScrollableContainer = (itemList) => {
  // const colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#0e0e0e'];
  return (
    <div className="scrollable-container">
      {itemList && itemList.length && itemList.map((item, index) => (
        <div className="canvas-item" key={index}>
          <ImageCanvasButton bgcolor='itemList' />
        </div>
      ))}
    </div>
  );
};

export default ScrollableContainer;
