import {atom} from 'jotai';

export class pixel {
    x: number;
    y: number;
    bEmpty: boolean = true;

    constructor(_x: number = 0, _y: number = 0, _bEmpty: boolean = true) {
        this.x = _x;
        this.y = _y;
        this.bEmpty = _bEmpty;
    }
}

export class pixelColor extends pixel {
    color: string;

    constructor(_x: number = 0, _y: number = 0, _bEmpty: boolean = true, _color: string = "#000000") {
        super(_x, _y, _bEmpty);
        this.color = _color;
    }
}

class IPixelGroup {
    points: Array<pixel>;
    color: string;
    constructor (_points: Array<pixel> = [], _color: string = "#000000") {
        this.points = _points;
        this.color = _color;
    }
}

class IPixelEffect {
    rotate: number;

    constructor(_rotate: number = 0) {
        this.rotate = _rotate;
    }
}

export class pixelState extends pixel{
    color: string;
    brushType: string;
    brushSize: number;

    constructor(_x:number =0, _y:number=0, _bEmpty:boolean=true, _color: string = "#000000", _brushType: string = "pencil", _brushSize: number = 1) {
        super(_x, _y, _bEmpty)
        this.color = _color;
        this.brushType = _brushType;
        this.brushSize = _brushSize;
    }
}

export class pixelLineAction extends IPixelGroup {
    brushType:string; // pencil, eraser
    brushSize:number;

    constructor(_points: Array<pixel> = [], _color: string = "#000000", _brushType: string = "pencil", _brushSize: number = 1) {
        super(_points, _color)
        this.brushType = _brushType;
        this.brushSize = _brushSize;
    }
}

export class pixelFullAction extends IPixelGroup {
    constructor(_points: Array<pixel> = [], _color: string = "#000000") {
        super(_points, _color)
    }
}

export class rotationEffect implements IPixelEffect {
    rotate: number = 0;
}

export class pixelFrame {
    pixelColors: Array<pixelColor> = [];
    effects: Array<IPixelEffect> = [];

    addPixelColor(_x: number, _y: number, _bEmpty:boolean, _color: string) {
        this.pixelColors.push({ x: _x, y: _y, bEmpty: _bEmpty, color: _color });
    }

    removePixelColor(_index: number) {
        this.pixelColors.splice(_index, 1);
    }

    addEffect(_effect: IPixelEffect) {
        this.effects.push(_effect);
    }

    removeEffect(_index: number) {
        this.effects.splice(_index, 1);
    }
}

export class pixelProject {
    frames: Array<pixelFrame> = [];
    currentFrame: number = 0;
    width: number = 32;
    height: number = 32;
    name: string = "New Project";

    addNewFrame() {
        this.frames.push(new pixelFrame());
    }

    removeFrame(_index:number){
        this.frames.splice(_index, 1);
    }
}

export const pixelProjectAtom = atom(new pixelProject());
export const getPixelProject = atom((get) => get(pixelProjectAtom));
export const resetPixelProject = atom(null, (get, set) => {
    set(pixelProjectAtom, new pixelProject());
});
export const getPixelProjectFrames = atom((get) => get(pixelProjectAtom).frames);
export const getPixelProjectFrame = atom((get, _index: number) => get(pixelProjectAtom).frames[_index]);
export const getPixelProjectFrameCount = atom((get) => get(pixelProjectAtom).frames.length);
export const getPixelProjectCurrentFrame = atom((get) => get(pixelProjectAtom).currentFrame);
export const getPixelProjectWidth = atom((get) => get(pixelProjectAtom).width);
export const getPixelProjectHeight = atom((get) => get(pixelProjectAtom).height);
export const getPixelProjectName = atom((get) => get(pixelProjectAtom).name);
export const setPixelProjectName = atom(
    null,
    (get, set, _name: string, _size:number) => {
        set(pixelProjectAtom, (prev) => {
            const newProject = new pixelProject();
            Object.assign(newProject, prev);
            newProject.name = _name;
            newProject.width = _size;
            newProject.height = _size;
            return newProject;
        });
    }
);

export const setPixelProjectCurrentFrame = atom(null, (get, set, _index: number) => {
    set(pixelProjectAtom, (prev) => {
        const newProject = JSON.parse(JSON.stringify(prev));
        newProject.currentFrame = _index;
        return prev;
    });
});
export const addPixelProjectNewFrame = atom(null, (get, set) => {
    set(pixelProjectAtom, (prev) => {
        const newProject = new pixelProject();
        Object.assign(newProject, prev);
        prev.addNewFrame();
        return prev;
    });
});
export const removePixelProjectFrame = atom(null, (get, set, _index: number) => {
    set(pixelProjectAtom, (prev) => {
        const newProject = new pixelProject();
        Object.assign(newProject, prev);
        prev.removeFrame(_index);
        return prev;
    });
});

export const setPixelProjectFrames = atom(null, (get, set, _frames: Array<pixelFrame>) => {
    set(pixelProjectAtom, (prev) => {
        const newProject = new pixelProject();
        Object.assign(newProject, prev);
        prev.frames = _frames;
        return prev;
    });
});
