import React from 'react';
import { Typography } from '@mui/material';

const CenteredText = ({ text, fontSize, color, fontFamily }) => {
    return (
        <Typography
            variant="body1"
            style={{
                fontSize: fontSize || '16px',
                color: color || 'black',
                fontFamily: fontFamily || 'Arial, sans-serif',
                textAlign: 'center',
            }}
        >
            {text}
        </Typography>
    );
};

export default CenteredText;
