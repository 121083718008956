const pixelizeCanvasRef = (canvas, ctx, gridSize) => {
    for (let x = 0; x < canvas.width; x += gridSize) {
        for (let y = 0; y < canvas.height; y += gridSize) {
            const pixelData = ctx.getImageData(x, y, gridSize, gridSize).data;
            const averageColor = getAverageColor(pixelData);
            ctx.fillStyle = `rgb(${averageColor.r}, ${averageColor.g}, ${averageColor.b})`;
            ctx.fillRect(x, y, gridSize, gridSize);
        }
    }
};

const resizeImage = (tempCanvas, imageData, resizeW, resizeH) => {
    return new Promise((resolve, reject) => {
        const tempContext = tempCanvas.getContext('2d', { willReadFrequently: true });
        tempCanvas.width = resizeW;
        tempCanvas.height = resizeH;
        
        const img = new Image();
        const blob = new Blob([new Uint8Array(imageData.data.buffer)], {type: 'image/png'});
        const url = URL.createObjectURL(blob);
        img.src = url;
        img.onload = () => {
            // 이미지를 캔버스에 그립니다. 이미지는 캔버스의 크기에 맞게 리사이즈됩니다.
            tempContext.drawImage(img, 0, 0, resizeW, resizeH);
            const dataUrl = tempCanvas.toDataURL('image/png');
            resolve(dataUrl);
        };
        img.onerror = reject;
    });
}


const getAverageColor = (pixelData) => {
    let totalR = 0;
    let totalG = 0;
    let totalB = 0;

    for (let i = 0; i < pixelData.length; i += 4) {
        totalR += pixelData[i];
        totalG += pixelData[i + 1];
        totalB += pixelData[i + 2];
    }

    const averageR = Math.floor(totalR / (pixelData.length / 4));
    const averageG = Math.floor(totalG / (pixelData.length / 4));
    const averageB = Math.floor(totalB / (pixelData.length / 4));

    return { r: averageR, g: averageG, b: averageB };
};

function adjustValue(x, thickness) {
  let remainder = x % thickness;
  x -= remainder;
  return x;
}

function getBresenhamLinePoints(x1, y1, x2, y2, thickness) {
  let dx = Math.abs(x2 - x1);
  let dy = Math.abs(y2 - y1);
  let sx = (x1 < x2) ? 1 : -1;
  let sy = (y1 < y2) ? 1 : -1;
  let err = dx - dy;
  let lastX = x1 - thickness, lastY = y1 - thickness;

  let list = [{x: x1, y: y1}];

  while(true){
    // Check the distance to the last drawn pixel
    let dist = Math.sqrt(Math.pow(x1 - lastX, 2) + Math.pow(y1 - lastY, 2));
    if (dist >= thickness ) {
        let x = adjustValue(x1, thickness);
        let y = adjustValue(y1, thickness);

        list.push({x: x, y: y});
        lastX = x;
        lastY = y;
    }

    if ((x1 === x2) && (y1 === y2)) break;
    let e2 = 2*err;
    if (e2 > -dy) { err -= dy; x1 += sx; }
    if (e2 < dx) { err += dx; y1 += sy; }
  }
  //  끝점은 추가.
  list.push({x: x2, y: y2});
  return list;
}

function drawBresenhamLine(ctx, x1, y1, x2, y2, thickness, bEraser = false) {
  let dx = Math.abs(x2 - x1);
  let dy = Math.abs(y2 - y1);
  let sx = (x1 < x2) ? 1 : -1;
  let sy = (y1 < y2) ? 1 : -1;
  let err = dx - dy;
  let lastX = x1 - thickness, lastY = y1 - thickness;

  while(true){
      // Check the distance to the last drawn pixel
      let dist = Math.sqrt(Math.pow(x1 - lastX, 2) + Math.pow(y1 - lastY, 2));
      if (dist >= thickness ) {
          let x = adjustValue(x1, thickness);
          let y = adjustValue(y1, thickness);


          if (bEraser) {
            //  delete a pixel with thickness
            ctx.clearRect(x, y, thickness, thickness);
          }else{
            ctx.fillRect(x, y, thickness, thickness); // draw a pixel with thickness
          }
          lastX = x;
          lastY = y;
      }

      if ((x1 === x2) && (y1 === y2)) break;
      let e2 = 2*err;
      if (e2 > -dy) { err -= dy; x1 += sx; }
      if (e2 < dx) { err += dx; y1 += sy; }
  }
}

//  베지어 곡선
const drawBezierCurve = (ctx, last, pnt, color, lineWidth) => {
    let lastPos = last
    let offset = pnt

    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.lineCap = 'round';

    // 베지어 곡선을 그립니다.
    ctx.beginPath();
    ctx.moveTo(lastPos.x, lastPos.y);
    ctx.quadraticCurveTo(lastPos.x, lastPos.y, offset.x, offset.y);
    ctx.stroke();
}

//  캐터멀-롬 스플라인
const drawCatmullRomSpline = (ctx, pointSet, color, lineWidth) => {
    const points = Array.from(pointSet)
    if (points.length < 4){
        return;
    }

    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';

    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);

    for (let i = 1; i < points.length - 2; i++) {
      let p0 = JSON.parse(points[i]);
      let p1 = JSON.parse(points[i + 1]);

      const cp1x = (p0.x + p1.x) / 2;
      const cp1y = (p0.y + p1.y) / 2;
      ctx.quadraticCurveTo(p0.x, p0.y, cp1x, cp1y);
    }

    let p_2 = points[points. length - 2];
    let p_1 = points[points.length - 1];
    ctx.quadraticCurveTo(
      p_2.x,
      p_2.y,
      p_1.x,
      p_1.y
    );

    ctx.stroke();   
}

//  캐터멀-롬 스플라인에 주변 그라디언트 효과를 추가
const drawCatmullRomSplinePlus = (ctx, pointSet, color, lineWidth) => {
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';

    const points = Array.from(pointSet)
    if (points.length < 4){
      return;
    }

    // Drawing the outer, blurrier lines for the gradient effect
    for (let i = 0; i < points.length - 1; i++) {
      let p0 = JSON.parse(points[i]);
      let p1 = JSON.parse(points[i + 1]);

      // Outer blurrier lines
      ctx.lineWidth = lineWidth+4;
      ctx.strokeStyle = `${color}33`; // 20% opacity
      ctx.beginPath();
      ctx.moveTo(p0.x, p0.y);
      ctx.lineTo(p1.x, p1.y);
      ctx.stroke();

      ctx.lineWidth = lineWidth+2;
      ctx.strokeStyle = `${color}66`; // 40% opacity
      ctx.beginPath();
      ctx.moveTo(p0.x, p0.y);
      ctx.lineTo(p1.x, p1.y);
      ctx.stroke();
    }

    // Drawing the central, solid line
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length - 1; i++) {
      const cp = {
        x: (points[i].x + points[i + 1].x) / 2,
        y: (points[i].y + points[i + 1].y) / 2,
      };
      ctx.quadraticCurveTo(points[i].x, points[i].y, cp.x, cp.y);
    }
    ctx.quadraticCurveTo(
      points[points.length - 2].x,
      points[points.length - 2].y,
      points[points.length - 1].x,
      points[points.length - 1].y
    );
    ctx.stroke();
};    

//  캐터멀-롬 스플라인에 주변 그라디언트 효과를 두툼하게 추가
const drawCatmullRomSpline2Plus = (ctx, points, color, lineWidth) => {
    if (points.size < 4) return; // 적어도 4개의 점이 필요합니다.

    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';

    // Drawing the outer, blurrier lines for the gradient effect
    ctx.lineWidth = lineWidth+8;
    ctx.strokeStyle = `${color}FF`; // 20% opacity
    CatmullRomSpline(ctx, points);

    ctx.lineWidth = lineWidth+6;
    ctx.strokeStyle = `${color}66`; // 40% opacity
    CatmullRomSpline(ctx, points);

    ctx.lineWidth = lineWidth+4;
    ctx.strokeStyle = `${color}E6`; // 80% opacity
    CatmullRomSpline(ctx, points);

    // Drawing the central, solid line
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    CatmullRomSpline(ctx, points);    
}

const CatmullRomSpline = (ctx, points) => {
    ctx.beginPath();
    for (let i = 0; i < points.size - 3; i++) {
      const p0 = points[i];
      const p1 = points[i + 1];
      const p2 = points[i + 2];
      const p3 = points[i + 3];

      for (let t = 0; t < 1; t += 0.05) {
        const x = CatmullRom(p0.x, p1.x, p2.x, p3.x, t);
        const y = CatmullRom(p0.y, p1.y, p2.y, p3.y, t);
        if (t === 0) ctx.moveTo(x, y);
        else ctx.lineTo(x, y);
      }
    }
    ctx.stroke();
  };

const CatmullRom = (p0, p1, p2, p3, t) => {
    const v0 = (p2 - p0) * 0.5;
    const v1 = (p3 - p1) * 0.5;
    return (2 * p1 - 2 * p2 + v0 + v1) * t * t * t + (-3 * p1 + 3 * p2 - 2 * v0 - v1) * t * t + v0 * t + p1;
}

export {pixelizeCanvasRef, resizeImage, getBresenhamLinePoints, drawBezierCurve, drawCatmullRomSpline, drawCatmullRomSplinePlus, drawCatmullRomSpline2Plus, drawBresenhamLine}