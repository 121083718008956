import React from 'react';
import TextField from '@mui/material/TextField';

const Spinner = ({ label, helperText, width, value, min, max, step, onChangeValue }) => {
    const handleSetValue = (_value) => {
        if (_value < min){
            onChangeValue(min);
        }else if(_value > max) {
            onChangeValue(max);
        }else{
            onChangeValue(_value)
        }
    };

    return (
        <div>
            <TextField
                type="number"
                label={label}
                value={value}
                sx={{ m: 2, width: {width} }}
                variant="standard"
                color="warning"
                helperText={helperText}
                focused
      
                inputProps={{
                    min: min,
                    max: max,
                    step: step,
                }}
                onChange={(e) => handleSetValue(parseInt(e.target.value, 10))}
            />
        </div>
    );
};

export default Spinner;
