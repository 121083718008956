import React, {useState} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

// import IconButton from '@mui/material/IconButton';
// import Avatar from '@mui/material/Avatar';
// import Tooltip from '@mui/material/Tooltip';
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const pages = [
//  {'title':'Converters', 'menu' : ['png to ico', 'image formats']},
  {'title':'Editors', 'menu' : ['pixel editor']},
  {'title':'Games', 'menu' : ['snake', 'arkanoid','packman']},

  // {'title':'Video Tools', 'menu' : ['youtube to']}, 
];

function NavBar( {onSetPage , accountInfo , cbAccountInfo}) {
  const [anchorElNavShort, setAnchorElNavShort] = useState(null);
  const [anchorElNavShortSub, setAnchorElNavShortSub] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [selectPage, setSelectPage] = useState(pages[0].title)
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenuShort = (e) => {
    setAnchorElNavShort(e.currentTarget)
  }
  const handleCloseNavMenuShort = () => {
    setAnchorElNavShort(null);
    handleCloseNavMenuShortSub();
  };  
  const handleOpenNavMenuShortSub = (e, page) => {
    setAnchorElNavShortSub(e.currentTarget)
    setSelectPage(page.title);
  }
  const handleCloseNavMenuShortSub = (submenu) => {
    setAnchorElNavShort(null);
    setAnchorElNavShortSub(null);
    const isSubMenuString = typeof submenu === 'string';
    if(isSubMenuString){
      onSetPage(submenu);
    }
  };    
  const handleOpenNavMenu = (e,page) => {
    setAnchorElNav(e.currentTarget);
    setSelectPage(page.title);
  };
  const handleCloseNavMenu = (submenu) => {
    setAnchorElNav(null);
    const isSubMenuString = typeof submenu === 'string';
    if(isSubMenuString){
      onSetPage(submenu);
    }
  };

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };
  
  return (
    <AppBar position="static" sx={{ backgroundColor: '#24262B', height: '80px' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src="/logo64.png" alt="Logo" onClick={()=>onSetPage('home')} style={{ cursor: 'pointer' }}/>
          <Typography
            variant="h6"
            noWrap
            component="a"
            m="1rem"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'BlackHanSans-Regular',
              fontWeight: 200,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
            style={{ cursor: 'pointer' }}
            onClick={()=>onSetPage('home')}
          >
          </Typography>
          {/** START : 화면 작을 때 나오는 메뉴 */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenuShort}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNavShort}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNavShort)}
              onClose={handleCloseNavMenuShort}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Button
                key={"Home"}
                sx={{ my: 2, color: 'black', display: 'block' }}
                onClick={()=>onSetPage('home')}
              > Home </Button>

            {pages.map((page) => (
              <Button
                key={page.title}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                <p onClick={(e) =>handleOpenNavMenuShortSub(e,page)}>{page.title}</p>
                <Menu
                  sx={{ ml: '70px', fontFamily: 'BlackHanSans-Regular'}}
                  id={"menu-appbar-"+page.title}
                  anchorEl={anchorElNavShortSub}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElNavShortSub && selectPage === page.title)}
                  onClose={handleCloseNavMenuShort}
                >
                  {page.menu.map((submenu) => (
                    <MenuItem key={submenu} onClick={()=>handleCloseNavMenuShortSub(submenu)}>
                      <Typography textAlign="center">{submenu}</Typography>
                    </MenuItem>
                  ))}
                </Menu>                
              </Button>
              ))}
            </Menu>
          </Box> 
          {/** END : 화면 작을 때 나오는 메뉴 */}           
          {/** START : PC 화면에서 출력되는 메뉴 */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                key={"Home"}
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={()=>onSetPage('home')}
              > Home </Button>

            {pages.map((page) => (

              <Button
                key={page.title}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <p onClick={(e) =>handleOpenNavMenu(e,page)}>{page.title}</p>
                <Menu
                  sx={{ mt: '45px', fontFamily: 'BlackHanSans-Regular'}}
                  id={"menu-appbar-"+page.title}
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElNav && selectPage === page.title)}
                  onClose={handleCloseNavMenu}
                >
                  {page.menu.map((submenu) => (
                    <MenuItem key={submenu} onClick={()=>handleCloseNavMenu(submenu)}>
                      <Typography textAlign="center">{submenu}</Typography>
                    </MenuItem>
                  ))}
                </Menu>                
              </Button>
            ))}
          </Box>
          {/** END : PC 화면에서 출력되는 메뉴 */}

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;

/**
 *  nav의 오른쪽 끝에 넣을 아이콘 및 메뉴 샘플
 *           <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Peter" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

 * 
 */