// uuid.js

function getSavedUUIDData(){
    var uuiddata = localStorage.getItem('device-id-info');
    if (!uuiddata) {
        // 기존의 ID가 없다면 새로운 ID를 생성하고 저장합니다.
        uuiddata = {
            uuid : generateRandomUUID(),
            type : 'random',
            shortId : generateRandomString(),
        }
        localStorage.setItem('device-id-info', JSON.stringify(uuiddata));
    }else {
        uuiddata = JSON.parse(uuiddata)
    }
    return uuiddata
}

async function resetRandomUUIDtoEmail(source_uuid, email)
{
    var uuiddata = localStorage.getItem('device-id-info');
    if(source_uuid !== uuiddata.uuid)
    {
        console.error('source uuid is incorrect!')
        return null;
    }

    generateUUID(email).then(uuid => {
        uuiddata = {
            uuid : uuid,
            type : 'email',
            shortId : email.chatAt(0),
        }
        localStorage.setItem('device-id-info', uuiddata);

        return uuid;
    }).catch((e) => {
        return null;
    });
}

function generateRandomString()
{
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 2; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

function generateRandomUUID()
{
    const array = new Uint32Array(4);
    window.crypto.getRandomValues(array);
    let result = '';
    for (let i = 0; i < array.length; i++) {
        result += array[i].toString(16).padStart(8, '0');
    }
    return result;
}

async function generateUUID(email) {
    // 이메일을 UTF-8 인코딩의 Uint8Array로 변환합니다.
    const encoder = new TextEncoder();
    const data = encoder.encode(email);
  
    // crypto.subtle.digest를 사용하여 SHA-256 해시를 생성합니다.
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
  
    // 해시된 데이터를 16진수 문자열로 변환합니다.
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  
    // UUID 형식에 맞게 문자열을 조정합니다.
    const uuid = `${hashHex.substring(0, 8)}-${hashHex.substring(8, 12)}-${hashHex.substring(12, 16)}-${hashHex.substring(16, 20)}-${hashHex.substring(20, 32)}`;
  
    return uuid;
}
  
export { getSavedUUIDData};
  